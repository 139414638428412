<template>
  <div class="product" @click="selectProduct" v-bind:class="{select: selected}">
    <div class="product-image">
      <img :src="imageUrl"/>
    </div>
    <div class="product-metadata">
      <h1>{{ product.name }}</h1>
      <p>{{ product.product_size }} | {{ displayPrice }} | {{product.retailer}} ID: {{ product.item_id }}</p>
      <p v-if="selected">Selected for alert</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'product-item',
  props: ['product', 'selectedProduct'],
  data() {
    return {
      buttonMessage: "Select me"
    };
  },
  methods: {
    selectProduct() {
      if (this.selected === true) {
        this.buttonMessage = "Select me";
        this.$emit('selectProduct', null);
      } else {
        this.buttonMessage = "Selected!"
        this.$emit('selectProduct', this.product);
      }
    },
  },
  computed: {
    imageUrl: function() {
      if (this.product) {
        let imageUrl = this.product.image_url;
        if (imageUrl.startsWith("http")) {
          return imageUrl;
        }
        return process.env.VUE_APP_MEDIA_BASE_URL + this.product.image_url;
      } else {
        return "";
      }
    },
    selected: function () {
      if (this.product === this.selectedProduct) {
        return true;
      }
      return false;
    },
    stockMessage: function () {
      if (this.product.availability === "ONLINE_ONLY") {
        return "Online only";
      } else if (this.product.availability === "BOTH") {
        return "Retail & online";
      } else if (this.product.availability === "STORE_ONLY") {
        return "Retail only";
      } else {
        return "";
      }
    },
    displayPrice: function () {
      let priceInCents = this.product.price;
      let priceInDollars = priceInCents / 100;
      return "$"+ priceInDollars.toFixed(2);
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.product {
  width: 490px;
  height: 170px;
  
  border-radius: 8px;
  border: 1px solid #000;
  background: #FFF;
  
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 14px;
  
  cursor: pointer;

  &.select {
    background: #863B33;
    transition:all 0.3s ease;
    border: 1px solid $emphasis;

    .product-image {
      outline: 1px solid $emphasis;
    }

    .product-metadata {
      h1 {
        color: white;
        text-decoration: underline;
        text-decoration-color: $emphasis;
      }

      p {
        color: white;
      }
    }

  }


  .product-image {
    // width: 100%;
    height: 100%;
    width: 80px;
    border-radius: 8px;
    background: white;
    padding: 0 0.2em;
    display: flex;
    justify-content: center;
    // overflow: hidden;

    img {
      padding: 2px 0;
      height: calc(100% - 4px);
      // width: 80px;
      // min-width: 80px;
      max-width: 80px;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .product-metadata {
    // where the product metadata lives
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin: 0;
    h1 {
      text-align: left;
      color: black;
      font-family: Assistant;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px; /* 130% */
      margin: 0;
    }

    p {
      text-align: left;
      color: var(--Secondary-gray, #999FAA);
      font-family: Assistant;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
      margin: 0;
    }

  }

  &:hover {
    background: #863B33;
    transition:all 0.3s ease;

    h1 {
      color: white;
    }

    h3 {
      color: white;
    }

    p {
      color: white;
    }

  }

}

@media only screen and (max-width: 600px) {
  .product {
    width: 250px;
    height: 150px;
    gap: 4px;
    padding-right: 4px;

    .product-image {
      
    }
    .product-metadata {
      h1 {
        font-size: 16px;
        font-weight: 500;
        line-height: 17px;
      }

      p {
        font-size: 12px;
        line-height: 12px;
      }
    }

    .small-button {
      margin: 1em auto;
      width: 150px;
      height: 36px;
    }
  }
}


</style>
