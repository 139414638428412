import Vue from 'vue';
import App from './App.vue';
import router from './router'
import store from './store'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";


Vue.config.productionTip = false;

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: "https://11a6d4bd18c047a39a08e4ad9be6ec12@o26722.ingest.sentry.io/6113582",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "127.0.0.1", "api.bottlebeagle.com"],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // logErrors: true,
  });
}


new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');

Vue.mixin({
  methods: {
    scrollToElement() {
      const el = this.$el;

      if (el) {
        const yOffset = -150;
        const y = el.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({top: y, behavior: "smooth"});
      }
    },
    timeConverter(unixTimestamp) {
      var date = new Date(unixTimestamp * 1000);
      return date
    },
    shortDate(unixTimestamp) {
      let date = this.timeConverter(unixTimestamp);
      return date.toLocaleDateString('en-US');
    },
    dateAndTime(unixTimestamp) {
      let date = this.timeConverter(unixTimestamp);
      return date.toLocaleString('en-US');
    },
  }
})
