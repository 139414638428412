import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
  },
  {
    path: '/dashboard/change_subscription',
    name: 'changesubscription',
    component: () => import(/* webpackChunkName: "change-subscription" */ '../views/ChangeSubscription.vue')
  },
  {
    path: '/dashboard/add_card',
    name: 'addcard',
    component: () => import(/* webpackChunkName: "add-card" */ '../views/AddCard.vue')
  },
  {
    path: '/dashboard/retry_invoice/:invoiceId',
    name: 'retryinvoice',
    component: () => import(/* webpackChunkName: "retry-invoice" */ '../views/RetryInvoice.vue'),
    props: true,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

export default router
