<template>
  <div class="tier" v-bind:class="{selected: isSelected, current: isCurrent}" @click="selectTier">
    <h1>{{ tier.name }}</h1>
    <h2 v-if="isCurrent">Your current tier</h2>
    <h2 v-else>{{ displayPrice }}<span v-if='tier.tax_amount'> + tax</span></h2>
    <div v-html="tier.description"></div>
  </div>
</template>

<script>

export default {
  name: 'TierItem',
  props: ['tier', 'isSelected', 'isCurrent'],
  data() {
    return {
      selected: false,
    };
  },
  methods: {
    selectTier() {
      if (this.isCurrent) {
        return;
      }
      
      this.selected = true;
      this.$emit('selectTier', this.tier);
    }
  },
  computed: {
    displayPrice: function () {      
      if (this.tier.total) {
        let priceInDollars = this.tier.subtotal / 100;
        var priceString= "$"+ priceInDollars.toFixed(2);
      } else if (this.tier.subtotal === 0) {
          return "Free";
      } else {
        let priceInDollars = this.tier.amount / 100;
        var priceString= "$"+ priceInDollars.toFixed(2);
      }
      
      if (this.tier.billing_period === 'monthly') {
        return priceString + " / mo";
      } else if (this.tier.billing_period === 'yearly') {
        return priceString + " / yr";
      } else {
        return priceString + ", one time";
      }
    }
  }
}
</script>

<style lang="scss">

.tier {
  text-align: left;

  width: 100%;
  // height: 150px;
  border: 1px solid black;
  border-radius: 8px;
  padding: 1em;

  background-color: white;

  h1 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    font-family: $header-font;
    text-decoration: underline;
  }

  &:nth-of-type(1) {
    h1 {
      color: #5469d4;
    }
  }

  &:nth-of-type(2) {
    h1 {
      color: $emphasis;
    }
  }

  &:nth-of-type(3) {
    h1 {
      color: #ffaf00;
    }
  }

  &:nth-of-type(4) {
    h1 {
      color: #890000;
    }
  }

  h2 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    span {
      font-weight: 400;
      font-size: 16px;
      color: #535a66;
    }
  }
  
  p {

  }

  div {
    // the UL description
    ul {
      margin: 4px;
      padding-left: 20px;
      font-size: 16px;
      color: #535a66;
      li {
        display: list-item;
        color: black;
        list-style-type: disc;
        em {
          // color: $emphasis;
          font-weight: 500;
          text-decoration: underline;
          text-decoration-color: $emphasis;
          font-style: normal;
          &.bigstuff {
            // styles for em with id 'bigstuff'
            font-weight: 600;
            font-family: $header-font;
            // color: $emphasis;
            // text-decoration: none;
          }
        }
      }
    }
  }

  &.selected {
    border: solid 1px $button-burgundy;
    color: white;
    background-color: $button-burgundy;

    div {
      ul {
        color: #EDD0C5;
        li {
          color: white;
        }
      }
    }
    &:last-child {
      h1 {
        color: #ffaf00;
      }
    }
  }

  &.current {
    // the current tier
    opacity: 0.3;
    cursor: default !important;
  }

  &:hover {
    transition:all 0.3s ease;
    cursor: pointer;
    border: 1px solid $emphasis;
  }

}



</style>
