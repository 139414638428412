<template>
  <div class="component card" id="product-search">
    <form @submit.prevent="search" ref="searchtextbox">
      <div class="title">
        <div class="searchbox" v-if="selectedRegion">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0464 9.80256C17.0464 13.5677 14.0053 16.6051 10.2722 16.6051C6.53914 16.6051 3.49805 13.5677 3.49805 9.80256C3.49805 6.0374 6.53914 3 10.2722 3C14.0053 3 17.0464 6.0374 17.0464 9.80256ZM15.512 18.079C13.9976 19.0453 12.2001 19.6051 10.2722 19.6051C4.87409 19.6051 0.498047 15.2164 0.498047 9.80256C0.498047 4.38876 4.87409 0 10.2722 0C15.6703 0 20.0464 4.38876 20.0464 9.80256C20.0464 12.1971 19.1903 14.3912 17.7682 16.0936L23.9824 22.3511L21.8684 24.4797L15.512 18.079Z" fill="black"/>
          </svg>
          <input v-model="searchterms" :placeholder="searchHelperText" >
          <a v-if="searchterms && !(searchterms === '')" @click.stop="resetSearch">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path></svg>
          </a>
          <svg width="10" height="10" v-else></svg>
        </div>
        <div class="region-select">
          <v-select :options="regions" label="name" v-model="selectedRegion" key="region" placeholder="Type or select a region" :filter-by="regionFilter">
            <template slot="selected-option" slot-scope="option">
                <span>{{ option.name }}</span>
            </template>
            <template #option="{ name, countryName }">
              <em>{{ countryName }}</em>  {{ name }}
            </template>
          </v-select>
        </div>
        <button class="big-button" v-if="selectedRegion">Search</button>
      </div>
    </form>

    <div v-if="searching && !errorMessage" class="loader"></div>
    <div v-if="errorMessage" class="error">
      <h3>{{errorMessage}}</h3>
      <button class='small-button' @click="resetSearch">Reset search</button>
    </div>
    <div v-if="results" class="results">
      <h3 v-if="results.length == 0">No results for <em>{{lastsearchterms}}</em></h3>
      <h3 v-else>{{ results.length }} results</h3>
      <div v-if="results.length > 0" class="product-display" ref="products">
        <product-item
          v-for="result in results"
          v-bind:key="result.item_id"
          v-bind:product="result"
          v-bind:selectedProduct="selectedProduct"
          @selectProduct="selectProduct"></product-item>
      </div>
    </div>
    <!-- <div v-if="selectedProduct" class="card-footer">
      <button class="big-button">Continue with X products</button>
    </div> -->
    <!-- <region-promo-modal v-if="selectedRegion && regionUnavailable && !hideModal" v-bind:regionInfo='selectedRegion' @regionPromoDismissed="regionPromoDismissed"></region-promo-modal> -->
  </div>
</template>

<script>
import { allCountries } from 'country-region-data';
import axios from 'axios';
import ProductItem from '@/components/product-item.vue';
// import RegionPromoModal from '@/components/region-promo-modal.vue';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

function initialData () {
  return {
    searchterms: null,
    lastsearchterms: null,
    searching: false,
    results: null,
    selectedProduct: null,
    selectedRegion: null,
    regionUnavailable: null,
    hideModal: false,
    errorMessage: null,
    startTime: Date.now(),
  };
}

export default {
  name: 'ProductSearch',
  props: ['regionInfo',],
  components: {
    'product-item': ProductItem,
    'v-select': vSelect,
    // RegionPromoModal,
  },
  data() {
    var data = initialData();
    var regions = allCountries.filter(country => (["CA", "US"].includes(country[1]))) // filter by valid countries
      .map(country => country[2].map(region=>region.concat([country[0], country[1]]))) // make list of region lists, plus country data
      .flat(1) // reduce lists into one big long list
      .filter(region => (["ON", "MB", "BC", "QC", "FL", "NS"].includes(region[1]))) // remove Nunavut
      .map(e => ({region: e[1], name: e[0], country: e[3], countryName: e[2]})) // make objects that are coherent
    data.regions = regions;
    return data;
  },
  mounted() {
    this.$nextTick(() => {
      // if, on mount, we have regionInfo, then assign it:
      this.selectedRegion = this.findRegionForGeoRegionInfo(this.regionInfo);
      this.hideModal = false;
      this.regionUnavailable = null;      
    });
  },
  watch: { 
    regionInfo: {
      immediate: true,
      handler (newVal, oldVal) { // watch it
        if (this.regions === null) {
          return;
        }
        this.selectedRegion = this.findRegionForGeoRegionInfo(newVal);
        this.hideModal = false;
        this.regionUnavailable = null;
      }
    },
    selectedRegion: {
      immediate: true,
      handler (newVal, oldVal) {
        this.results = null;
        this.$emit('didSelectRegion', this.selectedRegion);

        if (this.selectedRegion) {
          this.regions.sort((a,b) => {
            if (this.selectedRegion.country == a.country) {
              return -1;
            }
            if (this.selectedRegion.country == b.country) {
              return 1;
            }
            return 0;
          });
          }
      }
    },
  },
  methods: {
    wow() {
      console.log("wow");
    },
    findRegionForGeoRegionInfo(regionInfo) {
      if (regionInfo == null) {
        return null;
      }

      console.log(regionInfo);
      var region;
      var countryCode = regionInfo.countryCode;
      if (regionInfo.codes !== null) {
        // should be able to find a nice lil region, yerr
        // get code that's level 1:
        // first, we check for the `codes` match
        var firstLevelCode = regionInfo.codes.find(code => parseInt(code.level) == 1 && code.type == "ISO3166-2")
        if (firstLevelCode !== undefined) {
          // TODO: find region
          region = this.regions.find((region) => (region.region == firstLevelCode.code) && (region.country == countryCode));
        }

        if (region == undefined) {
          // unfortunately there was no matching thing in 'codes'
          var regionLevel = 3;
          var regionCode = '';
          
          while (regionLevel > 0) {
            if (regionInfo.hasOwnProperty("adminCode" + regionLevel)) {
              regionCode = regionInfo["adminCode"  + regionLevel];
              region = this.regions.find((region) => (region.region == regionCode) && (region.country == countryCode));
              if (region !== undefined) {
                console.log("Found region at level ", regionLevel, ": ", region);
                break;
              } else {
                console.log("Weird - we have an admincode and region code but couldn't find anything at region #", regionLevel, ":", regionCode, countryCode)
                regionLevel -= 1;
              }
            } else {
              // iterate
              regionLevel -= 1;
            }
          }
        }
      }

      return region;
    },
    regionFilter: (option, label, search) => {
      let temp = search.toLowerCase();
      return option.name.toLowerCase().indexOf(temp) > -1 || 
      option.countryName.toLowerCase().indexOf(temp) > -1
    },
    search() {
      if (!this.searchterms) {
        return;
      }

      if (this.$store.state.token == "") {
        if (window.fathom) {
          window.fathom.trackGoal('VH3QFX2V', 0);
        }

        if (window.rdt) {
          window.rdt('track', 'Search');
        }
      }
      
      this.lastsearchterms = this.searchterms;

      this.results = null;
      this.errorMessage = null;
      this.searching = true;
      axios({
        method: 'GET',
        url: 'search/',
        params: {
          search_terms: this.searchterms,
          region: this.selectedRegion,
        },
      }).then((response) => {
        this.searching = false;
        this.results = response.data;
  
        this.$nextTick(() => {
          this.$refs.products.scrollIntoView({ block: 'end', scrollBehavior: 'smooth' });
        });
      }).catch((error) => {
        console.log(error);
        this.searching = false;
        if (error.response) {
          // actually got a response + status code
          if (error.response.status == 404) {
            // TODO: toggle "regionUnavailable" and handle that
            this.regionUnavailable = true;
          } else {
            this.regionUnavailable = false;
          }
        } else if (error.request) {
          // request made but response not received
          this.errorMessage = "We ran into a problem with your search and have been notified!";
        } else {
          this.errorMessage = error.message;
        }
      });
    },
    selectProduct(product) {
      // submit up with search
      this.selectedProduct = product;
      if (!this.isLoggedIn) {
      }
      this.$emit('selectProductAndSearch', product, this.searchterms);
    },
    resetSearch() {
      if (this.searchterms != null) {
        this.searchterms = null;
        this.lastsearchterms = null;
        this.searching = false;
        this.results = null;
        this.selectedProduct = null;
        this.errorMessage = null;
        // this.$refs.searchtextbox.scrollIntoView({ block: 'center', scrollBehavior: 'smooth' });
        // this.$emit('resetCheckout');
      }
    },
    regionPromoDismissed(signedUp, newUser) {
      this.hideModal = true;
      this.selectedRegion = null;
      this.regionUnavailable = null;
      this.resetSearch();
      this.hideModal = false;
    },
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.state.token != "";
    },
    placeholderText: function() {
      if (this.selectedRegion === '' || !this.selectedRegion) {
        return "Select a region first";
      }
      return "Search in " + this.selectedRegion.name;
    },
    searchHelperText: function () {
      if (this.selectedRegion === '' || !this.selectedRegion) {
        return "Search for a bottle";
      }
      return "Search for a bottle in " + this.selectedRegion.name;
    },
    searchButtonText: function () {
      if (this.selectedRegion === '' || !this.selectedRegion) {
        return "Search in…";
      }
      
      if (this.selectedRegion.region === "ON") {
        return "Search in Ontario";
      } else if (this.selectedRegion.region === "QC") {
        return "Search in Quebec";
      } else if (this.selectedRegion.region === "BC") {
        return "Search in BC";
      } else if (this.selectedRegion.region == "SK") {
        return "Search in Saskatchewan";
      } else if (this.selectedRegion.region == "NS") {
        return "Search in Nova Scotia";
      } else if (this.selectedRegion.region == "MB") {
        return "Search in Manitoba";
      } else {
        return "Search in…"
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

#product-search {
  z-index: 20;
  position: relative;
}
h1 {
  max-width: 60%;
  margin: auto;
}
h3 {
  margin: 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

div {
  &.error {
    margin-top: 1em;
    margin-bottom: 1em;
    display: inline-block;
    h1 {
      color: red;
    }
    button {
      margin-top: 1em;
    }
  }
}

.title {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 16px;
  border-bottom: none;
  padding: 24px;
  
  p { // for the search helper text
    font-style: italic;
    font-size: 1em;
    margin-bottom: 0;
    margin-top: 1em;
  }

  .searchbox {
    display: flex;
    align-items: center;
    background: white;
    padding: 0 0.5em;
    border-radius: 12px;
    gap: 10px;
    flex-grow: 3;
    // min-width: 300px;

    svg {
      padding-left: 0px;
    }

    input {
      text-align: left;
      color: #000;
      background-color: transparent;
      font-family: Assistant;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 130% */
      height: 74px;
      width: 100%;
      min-width: 450px;
      padding: 0;
      outline:none;
      text-overflow: ellipsis;
      border-style: none;
      &::placeholder {
        color: black;
        font-style: italic;
        font-family: Assistant;
        text-overflow: ellipsis;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 130% */
      }
    }
  }
  .region-select {

    flex-grow: 2;

    h3 {
      margin-top: 0;
      display: inline-block;
      margin-right: 0.5em;
    }
    
    .v-select {
      display: inline-block;
      min-width: 250px;
      width: 100%;
      height: 74px;
      color: #000;
      font-family: Assistant;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 130% */
      // background-color: white;
      &::v-deep .vs__dropdown-toggle {
        background: white;
        height: 100%;
        border-radius:12px;
        border: 0;

        .vs__selected-options {

          .vs__selected {
            height: 100%;
            // position:relative;
            // display:block;
            justify-content: left;
            align-items: center;
            span {
              // height: 100%;
              // position:relative;
              // display:block;
            }
          }
          .vs__search {
          }
        }
      }
      .vs__dropdown-menu {
        background: red;
        z-index: 100;
        li {
          em {
            color: rgba($color: #000000, $alpha: 0.3);
            font-style: normal;
            font-weight: 100;
          }
      }
    }

    }

  }

  .big-button {
    flex-grow: 1;
  }
}

.card-footer {
    display: flex;
    border-top: none;
    justify-content: center;
    align-items: center;
  }


#product-search {
  margin-top: 6em;
  background-color: $search-card-background;
  .small-button {
    margin-bottom: 0.5em;
  }
  .results {
    margin-top: 0;
    padding: 0 12px 12px 12px;

    h3 {
      margin: 0 1em;
      text-align: center;
      color: #401D18;
      font-family: Assistant;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
    }
    .product-display {
      display: flex;
      flex-flow: column wrap;
      max-height: 400px;
      overflow-x: auto;
      padding: 14px;
      gap: 14px;
      // height: 120%;
      // width: 100%;
    }
  }
}


@media only screen and (max-width: 600px) {

  .title {

  .searchbox {
    min-width: none;
    input {
      min-width: unset;
    }
  }
}
  #product-search {
    margin: 4em 15px 0 15px;
    &.card{
      max-width: 100%;
    }

    .results {
      .product-display {
        gap: 4px;
      }
    }

  }
}

</style>
