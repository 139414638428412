<template>
  <div class="plan" v-bind:class="{selected: isSelected}" @click="selectPlan" :id="plan.id">
    <h1>{{ plan.title }}</h1>
    <p>{{ plan.description }}</p>
    <div v-if="plan.id=='yearly'"><h2>30% off</h2></div>
  </div>
</template>

<script>

export default {
  name: 'PlanItem',
  props: ['plan', 'isSelected'],
  data() {
    return {
      selected: false,
    };
  },
  methods: {
    selectPlan() {
      this.selected = true;
      this.$emit('selectPlan', this.plan);
    }
  }
}
</script>

<style lang="scss">

.plan {
  background-color: white;
  color: black;
  border-radius: 8px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  // gap: 4px;

  padding: 10px 5px;
  box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.25) inset;
  width: 268px;
  height: 80px;

  // &#btb {
  //   border: 1px solid black;
  //   background-color: white;
  //   color: black;
  //   font-weight: 300;
  //   &.selected {
  //     background-color: grey;
  //     color: white;
  //   }
  // }

  .discount {
    h1 {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  &.selected {
    background: $button-burgundy;
    color: white;
    // border: solid 1px black;

  }

  &:hover {
    transition:all 0.3s ease;
    cursor: pointer;
    box-shadow: none;
  }

  h1 {
    margin: 0;
    font-size: 24px;
    font-family: $header-font;
  }

  h2 {
    margin: 5px 0 0 0;
    font-size: 20px;
    font-style: italic;
    background-color: $emphasis;
    padding: 0 15px;
    text-align: center;
    color: white;
  }

  p {
    margin: auto;

    font-size: 14px;
  }
}




</style>
