<template>
  <div class="component card" id="phone" ref="phonetitle">
    <div class="title">
      <h1>Where should we reach you?</h1>
      <p>By signing up for Bottlebeagle, you agree to our <a href="https://assets.bottlebeagle.com/privacypolicy.html" target="_blank">Privacy Policy</a> and our <a href="https://assets.bottlebeagle.com/termsofservice.html" target="_blank">Terms of Service</a>.</p>
    </div>
    <div class="card-content" :class="{ 'cute-background': confirmed}">
      <phone-auth @authenticated="didAuthenticate"></phone-auth>
    </div>
  </div>
</template>

<script>
const PhoneAuth = () => import('./phone-auth.vue');

export default {
  name: 'PhoneLogin',
  components: { PhoneAuth },
  data() {
    return {
      inputtedPhoneNumber: this.$store.state.phoneNumber, // what's in the input
      submittedPhoneNumber: null, // what was sent to create a code
      pendingConfirmation: null,
      code: null,
    };
  },
  created() {
    if (this.confirmed) {
      this.$emit('authenticated', false);
    }
  },
  mounted() {
    this.scrollToElement();
  },
  methods: {
    didAuthenticate(newUser) {
      if (newUser == true) {
        if (window.fathom) {
          window.fathom.trackGoal('VQUGY8BS', 0);
        }

        if (window.rdt) {
          window.rdt('track', 'SignUp');
        }
      }
      
      this.confirmed = true;
      this.$emit('authenticated', newUser);
    },
  },
  computed: {
    confirmed: function() {
      return this.$store.state.phoneNumber != '';
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

#phone {
  background: transparent;

  .title {
    border-radius: 20px 20px 0 0;
    background: $card-background;
  }

  .cute-background {
    background-image: url(../assets/peeps.webp);
    background-position-x: 50%;
    background-position-y: 20%;
    background-size: cover;
  }
  .card-content {    
    border-radius: 0px 0px 20px 20px;
    border: 1px solid #6F5326;
    border-top: none;


    .phonebox {
    }
  }
}


@media only screen and (max-width: 600px) {
  .phonebox {
    width: 95%;
    // margin: 0 1em;
    input {
      font-size: 16px;
      // border-radius: 0;
    }
    button {
      font-size: 14px;
      max-width: 30%;
      height: 65px;
      // border-radius: 0;
    }
  }
}

</style>
