<template>
  <div id="app">
    <app-nav></app-nav>
    <router-view/>
    <p class="copyright">Copyright Strings.fm Inc., 2024 • Contact: <a href="mailto:andrew@bottlebeagle.com">andrew@bottlebeagle.com</a></p>
  </div>
</template>

<script>
import AppNav from '@/components/app-nav.vue';
import axios from 'axios';

export default {
  name: 'App',
  components: {
    AppNav,
  },
  created() {
    // set axios defaults
    console.log("Created");
    axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    // check for auth
    let token = localStorage.token;
    if (token) {
      this.$store.dispatch('setToken', token);
      axios.defaults.headers = {'Authorization': 'Token ' + token};

      // call getUser
      console.log("Calling getUser from app.created()");
      this.$store.dispatch('getUser');
    }
  }
}


</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montagu+Slab:opsz,wght@16..144,400;16..144,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&family=Assistant:wght@400&display=swap');

body {
  margin: 0;
  font-family: Assistant,AvenirNext-Regular,Helvetica,Arial,sans-serif,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Noto Sans,Liberation Sans,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  overflow-x: hidden;
}

html {
  background: #401D18;
  overflow-x: hidden;
}


body, input {
  font-family: $display-font;
}

.hide-hero {
  display: none;
}

.error {
  color: $error-color;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $text-base;
}

.component{
  font-family: $display-font;
}

.phonebox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;

  padding: 0 0.5em;
  border-radius: 12px;
  gap: 10px;
  margin: 0 auto;

  input{
    text-align: left;
    border-radius: 12px;
    background: $input-background;

    color: #000;
    font-family: Assistant;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 130% */

    height: 74px;
    max-width: 450px;
    min-width: 300px;
    text-overflow: ellipsis;
    border-style: none;
    padding: 0 14px;
    outline: none;
    &::placeholder {
      color: black;
      font-style: italic;
      font-family: Assistant;
      text-overflow: ellipsis;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 130% */
    }
    &:disabled {
      color: rgb(74, 74, 74);
    }
  }
}

.card {
  background: $card-header-background;
  // border: 1px solid $card-border;
  border-radius: 20px;

  max-width: 1135px;
  margin: 2em auto;

  color: $text-base;

  padding: 0;

  .helpful-note {
    margin: 0.5em 1em;
  }

  .inside-card {
    border-radius: 7px;    
    &:disabled {
      &:hover {
        background-color: grey;
      }
      border: 3px solid grey;
      cursor: progress;
    }

    padding: 15px 40px;

    // border: 3px solid white;
    background: white;
    margin: 1em auto;
    width: 60%;
    h1 {
      font-size: 16px;
      font-family: $display-font;
      font-weight: 600;
    }

  }

  .baby-inside-card {
    border-radius: 7px;    
    &:disabled {
      &:hover {
        background-color: grey;
      }
      border: 3px solid grey;
      cursor: progress;
    }

    padding: 3px 10px;

    // border: 3px solid grey;
    background: $card-background;
    margin: 1em auto;
    width: 60%;
    h1 {
      font-size: 16px;
      font-family: $display-font;
      font-weight: 600;
      margin: 0;
    }
    p {
      margin: 0;
    }

  }


  .title {
    padding: 24px;
    border-bottom: 1px solid $card-border;

    h1 {
      color: #000;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px; /* 72.222% */

      margin: auto;

      font-family: $header-font;
      text-align: center;
    }

    p {
      margin: 11px auto 0 auto;

      em {
        font-style: normal;
        font-weight: 600;
        text-decoration: underline;
      }

    }
  }

  .card-content {
    padding: 1em;
    background-color: $card-content-background;

  }
  .card-footer {
    padding: 1em;
    border-top: 1px solid $card-border;
    min-height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;


    a {
        flex: 1;
        color: black;
        .footer-back {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 125% */
          color: #000;
          text-decoration: underline;
          cursor: pointer;

          p {
            color: black;
            text-align: left;
            font-family: Abel;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 137.5% */
            text-decoration-line: underline;
          }


          &:before {
            margin-right:4px;
            content: ' ';
            background-image:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.98212 11.7614L0.741205 6.57614C0.419597 6.25795 0.419597 5.74206 0.741205 5.42386L5.98212 0.238643C6.30372 -0.0795476 6.82515 -0.0795476 7.14676 0.238643C7.46837 0.556834 7.46837 1.07272 7.14676 1.39092L3.3117 5.18522L14.5 5.18522V6.81478L3.3117 6.81478L7.14676 10.6091C7.46837 10.9273 7.46837 11.4432 7.14676 11.7614C6.82515 12.0795 6.30372 12.0795 5.98212 11.7614Z" fill="black"/></svg>');
            background-size: 14px 14px;
            height: 14px;
            width: 14px;
          }
       }
    }

    .big-button { // the footer's big button

    }

    .footer-right {
      flex: 1;
    }
  }
}


button {
  &:disabled {
    &:hover {
      background-color: grey;
    }
    background-color: grey;
    cursor: progress;
  }
}

.big-button {
  background: $emphasis;
  cursor: pointer;
  padding: 2px 12px;
  height: 74px;
  min-height: 60px;
  border-radius: 12px;
  border: 0;
  color: #FFF;
  font-family: Assistant;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.25) inset;


  &:hover {
    // background: #00818D;
    transition:all 0.3s ease;
    box-shadow: none;
  }
}

.thicker {
    font-weight: 600 !important;
  }

.big-button-alt {
  background: transparent;
  cursor: pointer;
  padding: 2px 12px;
  height: 74px;
  border-radius: 12px;
  border: 0;
  color: $emphasis;
  font-family: Assistant;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  box-shadow: none;


  &:hover {
    // background: #00818D;
    transition:all 0.3s ease;
    box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.25) inset;
  }

}


.medium-button {
  background: $emphasis;
  cursor: pointer;
  padding: 24px;
  border-radius: 12px;
  border: 0;
  color: #FFF;
  font-family: Assistant;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 130% */
  box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.25) inset;


  &:hover {
    // background: #00818D;
    transition:all 0.3s ease;
    box-shadow: none;
  }

}

.small-button {
  margin: 0.2em 1em;
  width: auto;
  height: auto;
  background: #000000;
  border: 1px solid #000000;
  border-radius: 8px;
  font-family: Assistant;
  font-weight: 700;
  padding: 6px 30px;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  outline: none;

  &:hover {
    background: #FFFFFF;
    color: #000000;
    transition:all 0.3s ease;
    cursor: pointer;
  }

  &.selected {
    background: #00818D;
  }

  &.red {
    background: #820000;

    &:hover {
      background: #fd0000;
      color: #FFFFFF;
      transition:all 0.3s ease;
      cursor: pointer;
    }

    &.selected {
      background: #fd0000;
    }
  }
  &.blue {
    background: #00A3B3;

    &:hover {
      background: #00818D;
      color: #FFFFFF;
      transition:all 0.3s ease;
      cursor: pointer;
    }

    &.selected {
      background: #820000;
    }
  }
}



.modal-container {
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1010;
    backdrop-filter: blur(10px);
  }

  .modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 22px;
    padding: 2em;
    position: fixed;
    max-width: 40em;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    background: $card-header-background;
    z-index: 1011;

    h1 {
      margin: 0 1em;
    }

    p {

    }

    .big-button {
      min-width: 200px;
    }

  }

  .login-modal {
    background: $card-background;
    color: $text-base;
  }

  .confirm-modal {
    background: red;
  }
}

a {
  color: $link;
}

body {
  .copyright {
    color: $card-background;
    text-align: center;
  }
}

.error-style {
  text-decoration: underline;
  text-decoration-color: $emphasis;
}

/* HTML: <div class="loader"></div> */
.loader {
  display: inline-block;
  margin: 12px auto;
  width: fit-content;
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  background:linear-gradient(90deg,#401D18 50%,#401D18 0) right/200% 100%;
  animation: l21 2s infinite linear;
}
.loader::before {
  content :"Loading...";
  color: #0000;
  padding: 0 5px;
  background: inherit;
  background-image: linear-gradient(90deg,$emphasis 50%,#401D18 0);
  -webkit-background-clip:text;
          background-clip:text;
}

@keyframes l21{
  100%{background-position: left}
}

@media only screen and (max-width: 600px) {
  body {
    // background-color: yellow;
  }


  .component {
    max-width: 100%;
  }

  .card {
    border-left-style: none;
    border-right-style: none;
    

    padding: 1em 0;

    .title {
      h1 {
      }
      p {
      }
    }

    .searchbox {
      width: 95%;
      // margin: 0 1em;
      input {
        font-size: 16px;
        // border-radius: 0;
      }
      button {
        font-size: 14px;
        width: 12em;
        max-width: 30%;
        height: 65px;
        // border-radius: 0;
      }
    }


    .card-footer {
      gap:14px;

      .footer-left {
        min-width: 140px;
      }

      .big-button {
        max-width: 220px;
      }

      .footer-right {
        display: none;
      }
    }
  }

  .phonebox {
      margin: 0 0;
      input {
        font-size: 20px;
        min-width: 180px;
        // border-radius: 0;
        // color: red;
        &::placeholder {
          font-size: 16px;
        }
      }
      button {
        font-size: 20px;

        min-width: 78px;
        // border-radius: 0;
      }
    }

}


@media only screen and (max-width: 900px) {

  .card-footer {
    
    .big-button {
      margin: 0.5em 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
      cursor: pointer;
    }
  }
}


</style>
