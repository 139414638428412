import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    phoneNumber: '',
    stripeAccount: {},
    alerts: [],
    email: '',
    subscribedToNewsletter: '',
    showedAgeModal: false,
  },
  mutations: {
    AUTH_USER (state, userData) {
      console.log("Auth user mutation");
      state.token = userData.token;
      state.stripeAccount = userData.stripe_account;
      state.phoneNumber = userData.phone_number;
      state.alerts = userData.alerts_for_user;
      state.email = userData.email;
      state.subscribedToNewsletter = userData.subscribed_to_newsletter;
    },
    LOGOUT_USER (state) {
      console.log("Logout user mutation");
      state.token = '';
      state.phoneNumber = '';
      state.stripeAccount = {};
      state.alerts = [];
      state.email = '';
      state.subscribedToNewsletter = '';
    },
    GET_USER (state, userData) {
      console.log("get user mutation");
      state.stripeAccount = userData.stripe_account;
      state.phoneNumber = userData.phone_number;
      state.alerts = userData.alerts_for_user;
      state.email = userData.email;
      state.subscribedToNewsletter = userData.subscribed_to_newsletter;
    },
    GET_ALERTS (state, alertData) {
      state.alerts = alertData;
    },
    PAUSE_ALERT (state, alertData) {
      state.alerts = alertData;
    },
    TOGGLE_NEWSLETTER (state, newsletterData) {
      state.subscribedToNewsletter = newsletterData;
    },
    DELETE_ALERT (state, alertData) {
      state.alerts = alertData;
    },
    DELETE_PAYMENT_METHOD (state) {
      state.stripeAccount.payment_method = null;
    },
    SET_TOKEN (state, token) {
      state.token = token;
    },
    SET_SUBSCRIPTION (state, subscription) {
      // takes the dict from CreateSubscriptionSerializer and saves it
      state.stripeAccount.subscription = subscription;
    },
    SHOWED_AGE_MODAL (state) {
      state.showedAgeModal = true;
    }
  },
  actions: {
    login ({ commit, store }, authData) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {

          var data = {
            phone_number: authData.phoneNumber,
            code: authData.code,
          }
          if (authData.email) {
            data.email = authData.email;
          }
          axios.post('verify_number/code/', data, {withCredentials: true, transformRequest: [(data, headers) => {
            if (headers.common) {
              delete headers.common.Authorization;
            }
            return data;
        },...axios.defaults.transformRequest]},
          ).then(response => {
            commit('AUTH_USER', response.data)
            // save token
            localStorage.setItem('token', this.state.token)

            // set token on axios
            axios.defaults.headers = {'Authorization': 'Token ' + this.state.token};
            resolve(response)
          }).catch((error) => {
            console.log(error);
            reject(error)
          })

        }, 1000)
      })
    },
    fullLogout ({commit}) {
      commit('LOGOUT_USER');
      // set token on axios
      delete axios.defaults.headers["Authorization"]
      localStorage.removeItem('token');

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          console.log("Yurrr login action");
          axios.get('logout', {withCredentials: true}
          ).then(response => {
            console.log("Logged out babyyy")
            resolve(response)
          }).catch((error) => {
            console.log(error);
            reject(error)
          })
        }, 1000)
      })
    },
    logout ({ commit }) {
      commit('LOGOUT_USER');
      // set token on axios
      delete axios.defaults.headers["Authorization"]
      localStorage.removeItem('token');
    },
    getUser ({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          axios.get('current_user/').then((response) => {
            // add user data to vuex
            commit('GET_USER', response.data)
            resolve(response);
          }).catch(function (error) {
            console.log(error);
            if (error.request.status === 403) {
              dispatch('logout');
            }
            reject(error);
          })
        }, 1000)
      })
    },
    getAlerts ({ commit }) {
      return new Promise((resolve, reject) => {
        console.log("Get user action");
        setTimeout(() => {
          axios.get('current_user/alerts/').then((response) => {
            // add user data to vuex
            commit('GET_ALERTS', response.data);
            resolve(response);
          }).catch(function (error) {
            console.log(error);
            reject(error);
          })
        }, 1000)
      })
    },
    pauseAlert ({ commit }, alert) {
      return new Promise((resolve, reject) => {
        console.log("Pause alert action");
        setTimeout(() => {
          axios.get('current_user/toggle_alert/' + alert.id + '/').then((response) => {
            console.log("Alert " + alert.id + " paused");

            // add user data to vuex
            commit('PAUSE_ALERT', response.data);
            resolve(response);
          }).catch(function (error) {
            reject(error);
          })
        }, 1000)
      })
    },
    toggleNewsletterSubscription ({ commit }) {
      return new Promise((resolve, reject) => {
        console.log("Newsletter toggle action");
        setTimeout(() => {
          axios.get('current_user/toggle_newsletter/').then((response) => {
            // add user data to vuex
            console.log("Response to toggle: " + response.data);
            commit('TOGGLE_NEWSLETTER', response.data);
            resolve(response);
          }).catch(function (error) {
            reject(error);
          })
        }, 1000)
      })
    },
    deleteAlert ({ commit }, alert) {
      return new Promise((resolve, reject) => {
        console.log("Delete alert action");
        setTimeout(() => {
          axios.delete('current_user/alerts/' + alert.id + '/').then((response) => {
            console.log("Alert " + alert.id + " deleted");

            // add user data to vuex
            commit('DELETE_ALERT', response.data);
            resolve(response);
          }).catch(function (error) {
            console.log(error);
            reject(error);
          })
        }, 1000)
      })
    },
    deletePaymentMethod ({ commit }, paymentMethodId) {
      return new Promise((resolve, reject) => {
        console.log("Delete payment method action");
        setTimeout(() => {
          axios.delete('current_user/payment_methods/' + paymentMethodId +'/').then((response) => {
            console.log("Deleted PM store returned");
            commit('DELETE_PAYMENT_METHOD');
            resolve(response);
          }).catch(function (error) {
            console.log(error);
            reject(error);
          })
        }, 1000)
      })
    },
    softDeletePaymentMethod({commit}) {
      commit('DELETE_PAYMENT_METHOD');
    },
    addPaymentMethod ({ commit }, { customerId, paymentMethodId, email }) {
      return new Promise((resolve, reject) => {
        console.log("Add payment method action");
        setTimeout(() => {
          axios.post('current_user/payment_methods/', {
            payment_method: paymentMethodId,
            email: email,
          }).then((result) => {

            commit('GET_USER', result.data);
    
            resolve(result);
          }).catch(function (error) {
            console.log(error);
            reject(error);
          });
        }, 1000)
      })
    },
    setToken ({ commit }, token) {
      console.log("Set token action");
      commit('SET_TOKEN', token);
    },
    setSubscription ({commit}, subscription) {
      commit('SET_SUBSCRIPTION', subscription);
    },
    showedAgeModal ({commit}) {
      commit('SHOWED_AGE_MODAL');
    }
  },
  getters: {

  }
})
